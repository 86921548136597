<template>
	<div class="home">
		<a-breadcrumb>
			<a-breadcrumb-item href="">
				<a-icon type="home" />
			</a-breadcrumb-item>
			<a-breadcrumb-item href="">
				<a-icon type="desktop" />
				<span>监控</span>
			</a-breadcrumb-item>
			<a-breadcrumb-item> 设备状态 </a-breadcrumb-item>
		</a-breadcrumb>

		<div class="search-list-box">
			<a-form class="ant-advanced-search-form">
				<a-row :gutter="24">
					<a-col :span="4">
						<a-form-item :label="`设备编号：`">
							<a-input placeholder="请输入设备编号" v-model="searchData.number" />
						</a-form-item>
					</a-col>
					<a-col :span="4">
						<a-form-item :label="`设备名称：`">
							<a-input placeholder="请输入设备名称" v-model="searchData.name" />
						</a-form-item>
					</a-col>
					<a-col :span="4">
						<a-button type="primary" @click="getList"> 查询 </a-button>
						<a-button :style="{ marginLeft: '8px' }" @click="handleReset">
							重置
						</a-button>
					</a-col>
				</a-row>
			</a-form>
			<div class="search-result-list">
				<div class="table-header-box">
					<div class="left">
						<h3 class="title">设备状态</h3>
					</div>
					<div class="right">
						<a-button type="primary" class="marginr20" @click="showDetail">
							模块状态
						</a-button>
					</div>
				</div>
				<a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="columns" @change="handleTableChange" :pagination="{total: total_page * 10}" :data-source="list" rowKey="id" size="small">

				</a-table>
			</div>
		</div>


		<a-modal v-model="detailShow" width="800px" title="详细信息" :footer="null">
			<a-spin :spinning="detailLoading">
				<a-form :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }" style="margin: 0 auto; width: 100%">
					<a-row :gutter="24">
						<a-col :span="12">
							<a-form-item label="设备名称：" >
								<a-input v-model="info.name" :disabled="true"/>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="设备编号：" >
								<a-input v-model="info.number" :disabled="true"/>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="设备状态：" >
								<a-input v-model="info.status_txt" :disabled="true"/>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="设备故障描述：">
								<a-input v-model="info.error_desc" :disabled="true"/>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="散存模块状态：">
								<a-input v-model="info.in_status_txt" :disabled="true"/>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="找零模块状态：">
								<a-input v-model="info.out_status_txt" :disabled="true"/>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="硬币卷模块状态：">
								<a-input v-model="info.outv_status_txt" :disabled="true"/>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="指示灯模块状态：">
								<a-input v-model="info.led_status_txt" :disabled="true"/>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="凭条打印模块状态：">
								<a-input v-model="info.print_status_txt" :disabled="true"/>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="监控摄像头状态：">
								<a-input v-model="info.camera_status_txt" :disabled="true"/>
							</a-form-item>
						</a-col>
					</a-row>
				</a-form>
				<div class="model-footer">
					<div class="left">
						<a-button type="primary" class="marginr20" html-type="submit" @click="detailShow = false">
							取消
						</a-button>
					</div>
					<div class="right">
						<a-button type="dashed" :disabled="isEditBtn('prev')" @click="editToData('prev')" class="" html-type="submit">
							上一条
						</a-button>
						<a-button type="dashed" :disabled="isEditBtn('next')" @click="editToData('next')" class="marginr20" html-type="submit">
							下一条
						</a-button>
					</div>
				</div>
			</a-spin>
		</a-modal>

	</div>
</template>

<script>
	import { ClientRequest } from 'http';
import moment from 'moment';
	import http from '../http'

	const columns = [{
			title: "设备编号",
			dataIndex: "number",
		},
		{
			title: "网点名称",
			dataIndex: "node_name",
		}, 
		{
			title: "设备位置",
			dataIndex: "area",
		},
		{
			title: "整机状态",
			dataIndex: "status_txt",
		},
		{
			title: "出币模块",
			dataIndex: "out_status_txt",
		},
		{
			title: "回收模块",
			dataIndex: "in_status_txt",
		}, 
		{
			title: "剩余1元(枚)",
			dataIndex: "out100",
		},
		{
			title: "剩余5角(枚)",
			dataIndex: "out50",
		},
		{
			title: "剩余1角(枚)",
			dataIndex: "out10",
		},
		{
			title: "剩余1元(卷)",
			dataIndex: "outv100",
		},
		{
			title: "剩余5角(卷)",
			dataIndex: "outv50",
		},
		{
			title: "剩余1角(卷)",
			dataIndex: "outv10",
		},
		{
			title: "总金额(元)",
			dataIndex: "amount",
		},
		{
			title: "清机次数",
			dataIndex: "clear_num",
		},
		{
			title: "最后清机时间",
			dataIndex: "clear_time",
		},
	];
	export default {
		data() {
			return {
				expand: false,
				columns,

				detailShow: false,

				info: {},

				searchData: {
					page: 1,
					name: '',
					number: ''
				},
				list: [],
				total_page: 0,

				// 表格选择的数据, 存储的是id
				selectedRowKeys: [],
				// 存储的是数据
				selectedRowDataList: [],
				// 当前是第几条编辑信息
				editIndex: 0,

				detailLoading: false,

				detailList: [],
				total_detail_page: 0,


				bankTreeData: [],
				outletsList: [],
			};
		},
		mounted() {
			this.getList()
		},
		created() {
			this.getBankTreeData()
			this.getOutletsList()
		},
		methods: {
			moment,
			handleReset() {
				this.searchData = {
					page: 1,
					bank_id: undefined,
					node_id: undefined,
					name:'',
					number:''
				}
				this.getList();
			},

			// 表格分页
			handleTableChange(pagination, filters, sorter) {
				console.log(pagination, filters, sorter)
				this.searchData.page = pagination.current
				this.getList();
			},
			// 表格选择
			onSelectChange(selectedRowKeys) {
				console.log('selectedRowKeys changed: ', selectedRowKeys);
				this.selectedRowKeys = selectedRowKeys;
			},
			// 列表
			getList() {
				http({
					method: 'post',
					url: '/api.device.status/',
					data: {
						...this.searchData,
						bank_id: this.searchData.bank_id || 0,
						node_id: this.searchData.node_id || 0,
					}
				}).then((res) => {
					this.list = res.data.list
					this.total_page = res.data.total_page
				})
			},
			// 日期解析
			handleChangeDate(date) {
				this.searchData.start_date = this.moment(date[0]).format('YYYY-MM-DD')
				this.searchData.end_date = this.moment(date[1]).format('YYYY-MM-DD')
			},

			showDetail() {
				// 判断是否已经选择数据
				if (this.selectedRowKeys.length == 0) {
					this.$message.warning('请先选择数据');
					return
				}
				console.log(this.selectedRowKeys)
				let selectDatas = []
				this.list.forEach((v) => {
					if (this.selectedRowKeys.includes(v.id)) {
						selectDatas.push(v)
					}
				})
				this.selectedRowDataList = JSON.parse(JSON.stringify(selectDatas))
				console.log(this.selectedRowDataList)
				this.editIndex = 0
				// 获取详情
				this.getDetail()
				this.detailShow = true
			},
			// 详情
			getDetail() {
				this.detailLoading = true
				http({
					method: 'post',
					url: '/api.device.status.detail/',
					data: {
						id: this.selectedRowDataList[this.editIndex].id
					}
				}).then((res) => {
					this.info = res.data
					this.detailLoading = false
				})
			},
			// 点击上一条 或者 下一条、
			editToData(type) {
				let index = -1
				if (type == 'prev') {
					index = this.editIndex - 1
					if (index == 0) {
						index = 0
					}
				} else if (type == 'next') {
					index = this.editIndex + 1
				}
				this.editIndex = index
				this.getDetail()
			},
			// 判断是否可以点击上一条 或者 下一条、
			isEditBtn(type) {
				if (type == 'prev') {
					if (this.editIndex == 0) {
						return true
					}
					if (this.selectedRowDataList == 1) {
						return true
					}
					if (this.editIndex >= 1) {
						return false
					}
				} else if (type == 'next') {
					if ((this.selectedRowDataList.length - 1) == this.editIndex) {
						return true
					}
				}
			},
			// 获取网点
			getOutletsList() {
				http({
					method: 'post',
					url: '/api.bank.node.treelist/',
					data: {
						page: 1,
						name: '',
						bank_id: 0
					}
				}).then((res) => {
					this.outletsList = res.data
				})
			},
			// 获取银行类型
			getBankTreeData() {
				http({
					method: 'post',
					url: '/api.bank.list/',
					data: {
						page: 1,
						parent_id: 0
					}
				}).then((res) => {
					this.bankTreeData = this.dataDealwith(res.data)
				})
			},
			dataDealwith(treeData) {
				function setGrayNode(data) { //遍历树  获取id数组
					for (var i = 0; i < data.length; i++) {
						data[i] = {
							value: data[i].bank_id,
							key: data[i].bank_id,
							title: data[i].name,
							children: data[i].list || []
						} //子节点传给另一个函数专门用来改数据
						if (data[i].children && data[i].children.length) { // 如果当前节点有子节点，就递归调用一次
							setGrayNode(data[i].children);
						}
					}
				}
				setGrayNode(treeData)
				return treeData;
			},
		},
	};
</script>
<style>
	.ant-advanced-search-form {
		padding: 24px;
		background: #fbfbfb;
		border: 1px solid #d9d9d9;
		border-radius: 6px;
	}

	.ant-advanced-search-form .ant-form-item {
		display: flex;
	}

	.ant-advanced-search-form .ant-form-item-control-wrapper {
		flex: 1;
	}

	.search-list-box{
		min-width: 980px;
	}

	.search-list-box .ant-form {
		max-width: none;
	}

	.search-list-box .search-result-list {
		margin-top: 16px;
		border: 1px dashed #e9e9e9;
		border-radius: 6px;
		background-color: #fafafa;
		min-height: 200px;
		text-align: center;
		padding-top: 80px;
	}

	.imgs {
		display: flex;
	}

	.imgs img {
		width: 140px;
		height: 200px;
	}
</style>
